.SchoolsBar {
  height: 64px;
  background: rgba(255, 255, 255, 1);
  border-top: solid 1px #eeeeee;

  .container {
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: space-between;
  }
  .schools {
    display: flex;
    align-items: center;
    height: 100%;
    overflow-x: auto;
  }
  .school {
    font-size: 16px;
    font-weight: 500;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 20px;
    background: #dbf4fd;
    color: #0cbeff;
    padding: 0 18px;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
  }
  .active {
    background: rgba(12, 190, 255, 1);
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }

  .addBtn {
    width: 40px;
    height: 64px;
    background: url(../assets/icon48_add.png) no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    cursor: pointer;
    flex-shrink: 0;
  }
}
