.engagementDlg {
  .ant-steps {
    margin-bottom: 30px;
    padding: 0 60px;
  }
  .ant-steps-item-title {
    line-height: 16px;
    font-size: 14px;
  }
  .steps-action {
    text-align: center;
  }

  .finish-panel {
    padding: 0 40px;
    width: 66%;
    margin: 0 auto;
  }

  .preview-panel {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    .phone {
      width: 189px;
      height: 374px;
      background: url(../../../../assets/pic_phone.png) no-repeat;
      background-size: cover;
      padding-top: 12px;
      padding-left: 14px;
    }

    .phone1 .phone-body {
      display: flex;
      flex-direction: column;
      background: #f5f7fa;
      .phone-body-p1 {
        background: url(../../../../assets/challenge_preview/community_header.png) no-repeat;
        background-size: 100% auto;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;
        height: 165px;
      }
      .phone-body-p2 {
        background: url(../../../../assets/challenge_preview/pic_bg.png) no-repeat;
        background-size: 100% auto;
        min-height: 320px;
        padding-left: 124px;
        background-color: #ffffff;
        padding-right: 36px;
        padding-top: 40px;
      }
      .phone-body-p3 {
        background: url(../../../../assets/challenge_preview/community_body2.png) no-repeat;
        background-size: 100% auto;
        flex-grow: 1;
        padding-left: 126px;
        padding-top: 18px;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.65);
        div {
          background-color: #ffffff;
          width: 120px;
        }
      }
      .phone-body-p4 {
        background: url(../../../../assets/challenge_preview/community_footer.png) no-repeat;
        background-size: 100% auto;
        height: 165px;
        border-bottom-left-radius: 60px;
        border-bottom-right-radius: 60px;
      }
      .p1-user {
        font-size: 28px;
        line-height: 40px;
        font-weight: 600;
      }
      .p1-title {
        font-size: 32px;
        font-weight: 600;
        padding: 20px 0 16px 0;
      }
      .p1-content {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 40px;
      }
    }

    .phone2 {
      margin-left: 100px;
    }
    .phone-body {
      width: 750px;
      height: 1630px;
      border-radius: 60px;
      background: #f5f7fa;
      transform: scale(0.214);
      transform-origin: 0 0;
    }
    .phone2 .phone-body {
      background-color: #ffffff;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .phone-body-p1 {
        background: url(../../../../assets/challenge_preview/detail_header.png) no-repeat;
        background-size: 100% auto;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        height: 176px;
        flex-shrink: 0;
      }

      .phone-body-p2 {
        background: url(../../../../assets/challenge_preview/pic_bg.png) no-repeat;
        background-size: 100% auto;
        border-bottom: solid 4px #f5f7fa;
        padding: 0 36px;
        flex-grow: 1;
        overflow-y: auto;
      }

      .part1 {
        padding-top: 40px;
        padding-bottom: 60px;
        .p1-title {
          font-size: 40px;
          font-weight: bold;
        }
        .p1-date {
          background: url(../../../../assets/challenge_preview/icon_deadline.png) no-repeat;
          background-size: 48px 48px;
          font-size: 32px;
          font-weight: bold;
          margin: 42px 0;
          padding-left: 72px;
        }
        .p1-content {
          font-size: 32px;
          line-height: 60px;
        }
      }
      .part2 {
        border-top: solid 1px #eaebec;
        padding-bottom: 60px;
        color: #191a1d;
        font-size: 32px;
        .part2-title {
          margin-top: 20px;
          font-weight: bold;
        }
        .part2-content-item {
          margin-top: 24px;
        }
        .part2-content {
          margin-top: 24px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
  .req-box {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #0cbeff;
    padding: 6px 24px 12px 12px;
    font-size: 14px;
    font-weight: 400;
    color: #191a1d;
    line-height: 21px;
  }
}
