.FrBar {
  width: 134px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-weight: 500;
    color: rgba(25, 26, 29, 1);
    line-height: 21px;
    text-align: center;
  }
  .info {
    font-size: 12px;
    font-weight: 500;
    color: rgba(130, 133, 135, 1);
    line-height: 18px;
    height: 36px;
    text-align: center;
  }
  .bar {
    width: 72px;
    height: 200px;
    background: rgba(245, 245, 245, 1);
    border-radius: 4px;
    margin: 8px 0;
  }
}
