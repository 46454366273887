.admin {
  .bars {
    display: flex;
    justify-content: space-around;
    padding: 0 30px;
    align-items: flex-end;
  }
  .itemBtn {
    width: 80%;
    height: 80px;
    margin-left: 72px;
    margin-right: 72px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    border: 1px solid rgba(238, 238, 238, 1);
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(25, 26, 29, 1);
    padding-left: 20px;
    padding-right: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 36px;
      height: 36px;
      margin-right: 20px;
      background-repeat: no-repeat;
    }
  }
  .itemBtn1 {
    .icon {
      background-image: url(../../assets/fun_start_new.png);
    }
  }
  .itemBtn2 {
    .icon {
      background-image: url(../../assets/fun_history.png);
    }
  }

  .line {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    min-height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(130, 133, 135, 1);
    padding: 0 16px;
  }

  .top10 {
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    tr {
      th {
        color: #191a1d;
        height: 48px;
        padding-left: 16px;
      }
      td {
        color: #828587;
        text-align: right;
        padding-right: 16px;
      }
    }
    tr:nth-child(even) {
      background-color: #f8f8f8;
    }
  }

  .stats {
    width: 100%;
    padding-left: 220px;
    display: flex;
    align-items: center;

    .ant-statistic {
      width: 160px;
      height: 112px;
      margin: 0 11px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      background: #f8f8f8;
      border-radius: 8px;
      padding: 12px;

      .ant-statistic-title {
        font-size: 12px;
      }
    }
  }

  .admin-history {
    td {
      vertical-align: top;
      padding: 12px 8px;
    }
  }

  .ant-table-expanded-row {
    > td {
      border-top: none;
      background-color: #fff;
    }
  }
}
