.fund-child-goals {
  border-radius: 2px;
  border: 1px solid #eeeeee;
  margin-bottom: 16px;
  .fund-child-goals-item {
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #828587;

    .fund-child-goal {
      // border-radius: 2px;
      // border: 1px solid #eeeeee;
      width: 100%;
    }

    .completed {
      font-weight: 700;
      color: green;
      font-size: 20px;
    }
  }
}
