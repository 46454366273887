.MobileMenu {
}

.moblie-menu-logo {
  background: url(../../assets/logo_blue.png) no-repeat;
  width: 123px;
  height: 32px;
  background-size: cover;
}

.ant-drawer-close {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.ant-drawer-body {
  .menus {
    .menu {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #828587;
    }
  }
}
