.WorkDlg {
  .post-box {
    height: 440px;
    padding: 20px 20px 0 20px;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
    overflow-y: auto;
    .post-title {
      font-size: 16px;
      font-weight: 600;
      color: #191a1d;
      line-height: 25px;
      margin-bottom: 20px;
    }
    .post-section {
      margin-bottom: 20px;
      img,
      video {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .commnets-box {
    height: 160px;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
    overflow-y: auto;
    padding: 20px 10px 0px 56px;
    margin: 20px 0;
    background: url(../../../assets/icon_comment.png) no-repeat;
    background-position: 20px 20px;

    .comment {
      font-size: 14px;
      color: #828587;
      line-height: 21px;
      margin-bottom: 20px;
    }
  }
  .input-box {
    height: 48px;
    display: flex;
    button {
      margin-left: 20px;
      width: 88px;
      height: 48px;
      border-radius: 4px;
    }
  }
}
