.friends-donate {
  .ant-list-item:nth-child(even) {
    background: #f8f8f8;
  }

  .ant-list-item {
    .status {
      font-size: 16px;
      font-weight: 500;
      color: #828587;
      padding-right: 16px;
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #191a1d;
      margin-left: 16px;
    }
  }
}

.no-donate-wrap {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
