@import "../../../common/fm.scss";

.challenge-events {
  .ant-tabs-nav-wrap {
    padding-left: 40px;
  }
}
.event-card {
  position: relative;
  .card-title {
    text-align: center;
    height: 64px;
    line-height: 64px;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    .close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
  .card-bar {
    height: 52px;
    display: flex;
    padding: 0 40px;
    background: #f8f8f8;
  }
  .card-bar-handler {
    position: relative;
    flex-grow: 1;
    height: 52px;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    padding-left: 40px;
    cursor: pointer;
    .ant-descriptions-item {
      padding-bottom: 0;
      .ant-descriptions-item-label {
        color: #999;
      }
      .ant-descriptions-item-content {
        color: #333;
      }
    }

    &:hover {
      .anticon-caret-down,
      .anticon-caret-up {
        color: #0cbeff;
      }
    }
  }
  .event-body {
    padding: 15px 40px;
    height: 450px;
    .students {
      overflow: auto;
      height: 360px;
    }

    .card-footer-tip {
      font-size: 8px;
      background: url(../../../assets/hand.png) no-repeat;
      background-size: 16px;
      background-position: left top;
      padding-left: 20px;
    }
  }

  .event-info {
    position: absolute;
    top: 52px;
    left: 0px;
    width: 100%;
    min-height: 420px;
    overflow: auto;
    z-index: 99;
    background: #f8f8f8;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;

    .descriptions-item {
      display: flex;
      .descriptions-item-label {
        color: #828587;
        width: 171px;
        flex-shrink: 0;
      }
      .descriptions-item-content {
        color: #333;
        padding-bottom: 26px;
        font-weight: 400;
        color: #191a1d;
        line-height: 21px;
      }
    }
  }
}
